.start {
  .hero {
    position: relative;
    background-color: $white;

    .hero-img {
      background-repeat: no-repeat;
      background-position: 100% top;
      background-attachment: fixed;
      background-size: cover;
      min-height: 400px;

      @media (min-width: $screen-md-min) {
        background-attachment: scroll;
      }
    }

    .hero-img-xs {
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center top;
      height: 200px;
    }
    .hero-text {
      position: relative;
      top: 0;
      left: 0;
      width: 100%;
      font-size: 3em;
      color: $primary-color;
      margin: 1em 0;
      text-align: center;

      @media (min-width: $screen-md-min) {
        position: absolute;
        top: 0;
        left: 0;
        text-align: left;
        margin: 3em 0;
        color: $primary-color;
      }

      .rotating-text {
        font-size: 0.9em;
        margin-bottom: 0.5em;
        display: none;

        @media (min-width: $screen-md-min) {
          font-size: 1.3em;
          display: inline;
        }
      }
      #js-rotating {
        color: $primary-color-darker;
        display: block;

        @media (min-width: $screen-md-min) {
          display: inline-block;
        }
      }

      h1 {
        font-size: 0.5em;

        @media (min-width: $screen-md-min) {
          font-size: 0.6em;
        }
      }

      h2 {
        font-size: 0.4em;

        @media (min-width: $screen-md-min) {
          font-size: 0.5em;
        }
      }
    }
  }

  .main-text {
    padding: 5em 1em;

    .highlighted {
      font-size: 2.5em;
      color: $primary-color;
    }
  }
}

#top-bar {
  padding: 0.8em 0;
  text-align: center;
  background-color: $primary-color;
  color: $primary-color-lighter;
  border-bottom: 1px solid $primary-color-light;

  .languages {
    text-transform: uppercase;

    .active {
      a {
        border-bottom: 2px solid $primary-color-lighter;

        &:hover {
          color: $primary-color-lighter;
        }
      }
    }

    a {
      color: $primary-color-lighter;
      border: 0;

      &:hover {
        color: $link-color-on-primary-color;
      }
    }
  }

  li:first-child {
    .fa {
      margin-left: 0;
    }
  }

  .fa {
    margin-left: 1em;
    padding-right: 0.3em;
  }

  @media (min-width: $screen-md-min) {
    text-align: right;
  }
}

#main-menu {
  background-color: $primary-color;
  border: 0;
  border-radius: 0;
  padding: 1em 0;
  margin-bottom: 0;

  .navbar-top {
    padding: 0.5em 0;
  }

  .navbar-nav {
    li {
      a {
        text-transform: uppercase;
        border: 0;
        padding: 1em 0 0.4em;
        margin: 0 1em;
        color: $white;
        border-bottom: 2px solid transparent;

        @media (min-width: $screen-md-min) {
          line-height: 30px;
        }

        &:hover {
          //color: $primary-color-darker;
          border-bottom-color: $primary-color-lighter;
        }
      }
    }
  }

  .navbar-header {
    margin-bottom: 1em;

    .navbar-brand {
      width: 35px;
      height: 35px;
      border: 0;
      margin-left: 0;
      background-image: url("../images/logo_white_small.png");
      background-repeat: no-repeat;

      @media (min-width: $screen-md-min) {
        width: 60px;
        height: 60px;
        background-image: url("../images/logo_white.png");
      }
    }

    .navbar-toggle {
      border: 0;
      border-radius: 0;
      padding: 0;
      margin: 10px;

      &:hover,
      &:focus {
        background-color: transparent;
      }

      .navbar-bars {
        float: left;
        margin-top: 3px;

        .icon-bar {
          background-color: $primary-color-lighter;
          height: 3px;
        }
      }

      .navbar-label {
        color: $primary-color-lighter;
        text-transform: uppercase;
        float: left;
        margin-right: 10px;
      }
    }
  }

  .navbar-collapse {
    border-color: $primary-color-lighter;
  }
}

#headline {
  .headline-image {
    height: 250px;
    background-size: cover;
    background-repeat: no-repeat;
    // background-position: 50% 70%;
    //background-attachment: fixed;

    @media (min-width: $screen-md-min) {
      background-attachment: scroll;
      // background-position: 50% 70%;
      min-height: 400px;
      height: 70vh;
    }
  }

  .headline-title {
    background-color: $gray-light;
    border-bottom: 1px solid $gray;
    padding: 2.5em 0 1em;
  }
}

#intro {
  background-color: $gray-light;
}

#main {
  padding: 4em 0;
  a {
    color: $link-color;
    border-bottom: 1px dotted $link-color;
    padding-bottom: 3px;
    transition: all 0.3s ease;

    &:hover,
    &:focus {
      color: $link-color-hover;
      border-bottom-color: $link-color-hover;
      text-decoration: none;
    }

    &:active {
      color: $link-color;
      border-bottom-color: $link-color;
      text-decoration: none;
    }
  }
}

#bottom {
  position: relative;
  color: $white;

  .bottom-bg {
    position: absolute;
    background-position: center;
    background-size: cover;
    -webkit-filter: grayscale(1);
    filter: grayscale(1);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    &::after {
      content: "\A";
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.7);
    }
  }

  .bottom-content {
    padding: 7em 5em;

    .price {
      font-size: 140%;
      @media (min-width: $screen-sm-min) {
        font-size: 220%;
      }
    }
  }
}

#footer {
  background-color: $footer-bg;
  color: $white;
  padding: 3em 0 3em;

  a {
    color: $white;
    border-bottom-color: $white;

    &:hover {
      color: $link-color-hover;
      border-bottom-color: $link-color-hover;
      cursor: pointer;
    }
  }

  .fa-heart,
  .fa-heart-o {
    color: $primary-color-lighter;
  }

  .policies {
    font-size: 1.2em;
  }

  .bottom-footer {
    margin-top: 3em;

    small p,
    small a {
      color: lighten($primary-color, 15%);
      border-color: lighten($primary-color, 15%);
    }

    small a:hover {
      color: $link-color-hover;
      border-color: $link-color-hover;
    }
  }
}

.top-footer {
  padding: 1em 0;
  background-color: $footer-social-bg;

  ul {
    padding: 1.2em 0;

    li {
      margin-right: 0.5em;
      a {
        font-size: 1.2em;
        &:hover {
          cursor: pointer;
        }
      }
    }

    &.social-icons {
      padding: 0;

      a {
        font-size: 2.5em;
        text-decoration: none;
        border: 0;
      }
    }
  }
}

.modal-content {
  border-radius: 0;
  padding: 1em;

  h4 {
    display: inline;
  }
}

// Styles for the contact form
.uniform__potty {
  position: absolute;
  top: -9999px;
  left: -9999px;
}

label {
  color: $gray-dark;
  transition: color ease-in-out 0.15s;

  &.focus {
    color: $primary-color;
  }
}

.checkbox {
  margin: 1em 0;
}

.checkbox-inline {
  margin-bottom: 1em;

  @media (min-width: $screen-sm-min) {
    margin-bottom: 0;
  }
}

.form-control {
  border: 1px solid $gray-dark;
  border-radius: 0;
  box-shadow: none;

  &:focus {
    border-color: $primary-color;
    box-shadow: none;
  }
}

.contact-form-wrapper {
  @media (min-width: $screen-sm-min) {
    padding-right: 4em;
  }
}

.contact-info {
  padding-top: 2em;
  padding-bottom: 2em;

  @media (min-width: $screen-sm-min) {
    padding: 2em;
  }
}

#feedback {
  border-radius: 2px;
  display: none;
}

/*--------------------------
List sections
--------------------------*/
.list-section {
  padding: 4em 0;
  border-bottom: 1px solid $gray-light;

  &:last-child {
    border-bottom: 0;
  }

  .section-image {
    max-width: 150px;
    opacity: 0.8;
    padding: 2em;
  }

  .section-heading {
    text-align: center;

    @media (min-width: $screen-sm-min) {
      text-align: left;
    }
  }
}

/*--------------------------
List alternative sections
--------------------------*/
.list-alt-sections {
  padding: 0;

  @media (min-width: $screen-sm-min) {
    padding: 3em;
    margin-top: 0;
  }

  .list-alt-section {
    padding: 0;

    @media (min-width: $screen-sm-min) {
      padding: 3.5em 0;
    }

    .section-image {
      max-width: 150px;
      opacity: 0.8;
      padding: 2em;
    }

    .section-heading {
      color: $primary-color;
      margin-bottom: 1em;
    }

    .section-number {
      display: block;
      margin-right: 1em;
      font-size: 3rem;
      text-align: left;

      @media (min-width: $screen-sm-min) {
        text-align: right;
      }
    }
    .section-text {
      padding: 2em 4em;
    }
  }
}

/*--------------------------
Grid sections
--------------------------*/
.grid-sections {
  .grid-section {
    padding: 0 2em;
    margin-bottom: 1em;
    min-height: 300px;
    overflow: visible;

    @media (min-width: $screen-sm-min) {
      margin-bottom: 2em;
    }

    .section-image {
      max-height: 80px;
      opacity: 0.8;
      margin-bottom: 2em;
    }

    .section-heading {
      margin-bottom: 1em;
    }
  }
}

/*--------------------------
List image sections
--------------------------*/
.list-image-sections {
  padding: 0;
  margin-top: -30px;

  @media (min-width: $screen-sm-min) {
    padding: 4em;
    margin-top: 0;
  }

  .list-image-section {
    padding: 0;

    @media (min-width: $screen-sm-min) {
      padding: 5em 0;
    }

    .section-image {
      padding: 0;
      position: relative;
    }
    .section-text {
      padding: 2em 4em;
    }
  }
}

/*--------------------------
List numbers sections
--------------------------*/
.list-numbers-sections {
  padding: 0;

  @media (min-width: $screen-sm-min) {
    padding: 3em;
    margin-top: 0;
  }

  .list-numbers-section {
    padding: 0;

    @media (min-width: $screen-sm-min) {
      padding: 3.5em 0;
    }

    .section-heading {
      color: $primary-color;
      margin-bottom: 1em;
    }

    .section-number {
      display: block;
      margin-right: 1em;
      font-size: 3rem;
      text-align: left;

      @media (min-width: $screen-sm-min) {
        text-align: right;
      }
    }
    .section-text {
      padding: 2em 4em;
    }
  }
}

/*--------------------------
Quotes sections
--------------------------*/
.quotes {
  padding: 0;
  margin-top: -30px;

  @media (min-width: $screen-sm-min) {
    padding: 4em;
    margin-top: 0;
  }

  .quote {
    padding: 0;

    @media (min-width: $screen-sm-min) {
      padding: 5em 0;
    }

    .quote-image {
      padding: 0;
      position: relative;
    }
  }
}

/*----------------------
-- Blog
----------------------*/
.blog {
  background-color: #fff;

  .content {
    padding: 2em 3em;

    .archive {
      margin-bottom: 4em;
      padding: 1em;
      border: 1px solid $gray;

      a {
        color: $gray-dark;
        border-bottom: 1px dotted transparent;
        padding-bottom: 4px;

        &:hover {
          color: $gray-dark;
          border-bottom: 1px dotted $gray-dark;
        }
      }

      .active {
        a {
          border-bottom: 1px dotted $gray-dark;
        }
      }
    }

    .post {
      padding: 2em 0;

      .post-header {
        margin-bottom: 2em;

        .post-meta {
          opacity: 0.8;
        }
      }

      .cover-media {
        margin-top: 2em;
      }

      .post-image {
        width: 100%;
      }

      .post-body {
        margin-top: 40px;
      }

      .post-footer {
        margin-top: 3em;
        padding-top: 1.5em;
        border-top: 1px dotted $gray;

        .tags {
          a {
            border-radius: 0;
            border: 0;
            font-size: 1em;

            &.label-primary {
              background-color: $primary-color;

              &:hover {
                background-color: $primary-color-dark;
                border: 0;
              }
            }
          }
        }
      }
    }

    .btn {
      margin-left: 0;
      padding: 10px 20px;
    }
  }
}

@media (min-width: 768px) {
  .blog {
    .content {
      padding: 2em 10em;

      .post {
        margin-bottom: 2em;

        .post-title {
          font-size: 3em;
        }

        .btn {
          padding: 15px 25px;
        }
      }
    }
  }
}

.callToAction {
  background-color: $primary-color;
  color: $white;
  padding: 2em;
  text-align: center;
}
