/* Buttons */

.btn {
    font-size: 18px;
    display: inline-block;
    color: white;
    border: 2px solid transparent;
    border-radius: 2px;
    background: transparent;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.btn-lg {
  padding: 12px 30px;
  margin: 5px 0px;
}

.btn:hover,
.btn:focus {
    color: $white;
}

.btn-primary {
    color: $white;
    background: $primary-color;
}

.btn-primary:hover {
    background: $primary-color-light;
    border-color: $primary-color-light;
}

.btn.btn-primary-fill {
    color: $primary-color;
    border-color: $primary-color;
    background: transparent;
}

.btn.btn-primary-fill:hover {
    color: $white;
    background: $primary-color;
}

.btn.btn-primary-dotted-fill {
    color: $primary-color;
    border: 2px dotted $primary-color;
    background: transparent;
}

.btn.btn-primary-dotted-fill:hover {
    color: $white;
    background: $primary-color;
}

.btn.btn-white-fill {
    color: $white;
    border-color: $white;
    background: transparent;
}

.btn.btn-white-fill:hover {
    color: $primary-color;
    background: $white;
}

.btn.btn-gray-fill {
    color: $gray;
    border-color: $gray-dark;
    background: transparent;
}

.btn.btn-gray-fill:hover {
    border-color: $gray-dark;
    background: $gray-dark;
}
