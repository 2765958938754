/* ==========================================================================
   Helper classes
   ========================================================================== */

.no-padding-top {
  padding-top: 0 !important;
}

.no-padding-bottom {
  padding-bottom: 0 !important;
}

.no-margin-top {
  margin-top: 0 !important;
}

.no-margin-bottom {
  margin-bottom: 0 !important;
}

.padding-top-50 {
  padding-top: 25px !important;

  @media (min-width: $screen-md-min) {
    padding-top: 50px;
  }
}

.padding-bottom-50 {
  padding-bottom: 25px !important;

  @media (min-width: $screen-md-min) {
    padding-top: 50px;
  }
}

.margin-top-50 {
  margin-top: 25px !important;

  @media (min-width: $screen-md-min) {
    padding-top: 50px;
  }
}

.margin-bottom-50 {
  margin-bottom: 25px !important;

  @media (min-width: $screen-md-min) {
    padding-top: 50px;
  }
}

.gray {
  background-color: $gray-light;

  .page-header {
    border-bottom-color: $gray-dark;
  }
}

.white {
  background-color: $white;
}

.blue {
  background-color: $primary-color;
}

.text-center-mobile {

  @media (max-width: $screen-sm-min) {
    text-align: center;
    margin: 1em 0;
  }
}
