@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,700,400italic,700italic);
@import url(https://fonts.googleapis.com/css?family=Varela);

// colors
$primary-color: #02a7df !default;
$primary-color-dark: #028FBF;
$primary-color-darker: #1E4F5F;
$primary-color-light: #51caf6;
$primary-color-lighter: lighten($primary-color-light, 25%);
$black: #000 !default;
$white: #fff !default;
$gray: #ddd;
$gray-dark: #888;
$gray-light: #efefef;

$link-color: $primary-color;
$link-color-hover: $primary-color-darker;

$link-color-on-primary-color: $white;
$link-color-hover-on-primary-color: $primary-color-darker;


// fonts
$body-font-family: 'Open Sans', sans-serif;
$headline-font-family: 'Varela', sans-serif;

//menu
$menu-link: $gray;

// media querys
$screen-sm-min: 768px;
$screen-md-min: 992px;
$screen-lg-min: 1200px;

// footer
$footer-social-bg: $primary-color-dark;
$footer-bg: $primary-color;
